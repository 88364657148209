export const languages =  [
	{
		name: 'English',
		code: 'en',
		typeface: 'Noto Sans',
		inLanguage: 'English',
	},
	{
		name: 'Arabic',
		code: 'ar',
		typeface: 'Dubai',
		inLanguage: 'العربية',
	},
	{
		name: 'Assyrian',
		code: 'aii',
		typeface: 'Noto Sans',
		inLanguage: 'ܐܵܬܘܿܪܵܝܵܐ',
	},
	{
		name: 'Bengali',
		code: 'bn',
		typeface: 'Noto Sans',
		inLanguage: 'বাংল',
	},
	{
		name: 'Burmese',
		code: 'my',
		typeface: 'Noto Sans Myanmar',
		inLanguage: 'မြန်မာဘာသာ',
	},
	{
		name: 'Chinese (Simplified)',
		code: 'zh-haus',
		typeface: 'Noto Sans SC',
		inLanguage: '简体中文',
	},
	{
		name: 'Chinese (Traditional)',
		code: 'zh-hant',
		typeface: 'Noto Sans TC',
		inLanguage: '繁體中文',
	},
	{
		name: 'Croatian',
		code: 'hr',
		typeface: 'Noto Sans',
		inLanguage: 'Hrvatski',
	},
	{
		name: 'Dari',
		code: 'fa-af',
		typeface: 'Dubai',
		inLanguage: 'دری',
	},
	{
		name: 'Dinka',
		code: 'din',
		typeface: 'Noto Sans',
		inLanguage: 'Thuɔŋjäŋ',
	},
	{
		name: 'Farsi',
		code: 'fa-ir',
		typeface: 'Dubai',
		inLanguage: 'فارسی',
	},
	{
		name: 'Filipino',
		code: 'fil',
		typeface: 'Noto Sans',
		inLanguage: 'Filipino',
	},
	{
		name: 'French',
		code: 'fr',
		typeface: 'Noto Sans',
		inLanguage: 'Français',
	},
	{
		name: 'Greek',
		code: 'el',
		typeface: 'Noto Sans',
		inLanguage: 'Ελληνικά',
	},
	{
		name: 'Gujarati',
		code: 'gu',
		typeface: 'Noto Sans',
		inLanguage: 'ગુજરાતી',
	},
	{
		name: 'Hazaragi',
		code: 'haz',
		typeface: 'Noto Sans',
		inLanguage: 'هزارگی',
	},
	{
		name: 'Hindi',
		code: 'hi',
		typeface: 'Noto Sans',
		inLanguage: 'हिन्दी',
	},
	{
		name: 'Indonesian',
		code: 'in',
		typeface: 'Noto Sans',
		inLanguage: 'Bahasa Indonesia',
	},
	{
		name: 'Italian',
		code: 'it',
		typeface: 'Noto Sans',
		inLanguage: 'Italiano',
	},
	{
		name: 'Japanese',
		code: 'ja',
		typeface: 'Noto Sans',
		inLanguage: '日本語',
	},
	{
		name: 'Karen',
		code: 'ksw-mymr',
		typeface: 'Noto Sans',
		inLanguage: 'ကညီကျိာ်',
	},
	{
		name: 'Khmer',
		code: 'km',
		typeface: 'Noto Sans',
		inLanguage: 'ភាសាខ្មែរ',
	},
	{
		name: 'Korean',
		code: 'ko',
		typeface: 'Noto Sans',
		inLanguage: '한국어',
	},
	{
		name: 'Lao',
		code: 'lo',
		typeface: 'Noto Sans',
		inLanguage: 'ພາສາລາວ',
	},
	{
		name: 'Macedonian',
		code: 'mk',
		typeface: 'Noto Sans',
		inLanguage: 'Mакедонски',
	},
	{
		name: 'Nepali',
		code: 'ne',
		typeface: 'Noto Sans',
		inLanguage: 'नेपाली',
	},
	{
		name: 'Polish',
		code: 'pl',
		typeface: 'Noto Sans',
		inLanguage: 'Polski',
	},
	{
		name: 'Portuguese',
		code: 'pt',
		typeface: 'Noto Sans',
		inLanguage: 'Português',
	},
	{
		name: 'Punjabi',
		code: 'pa',
		typeface: 'Noto Sans',
		inLanguage: 'ਪੰਜਾਬੀ',
	},
	{
		name: 'Russian',
		code: 'ru',
		typeface: 'Noto Sans',
		inLanguage: 'Русский',
	},
	{
		name: 'Samoan',
		code: 'sm',
		typeface: 'Noto Sans',
		inLanguage: 'Gagana Samoa',
	},
	{
		name: 'Serbian',
		code: 'sr-cyrl',
		typeface: 'Noto Sans',
		inLanguage: 'Cрпски',
	},
	{
		name: 'Sinhalese',
		code: 'sl',
		typeface: 'Noto Sans',
		inLanguage: 'සිංහල',
	},
	{
		name: 'Somali',
		code: 'so',
		typeface: 'Noto Sans',
		inLanguage: 'Soomaali',
	},
	{
		name: 'Spanish',
		code: 'es',
		typeface: 'Noto Sans',
		inLanguage: 'Español',
	},
	{
		name: 'Swahili',
		code: 'sw',
		typeface: 'Noto Sans',
		inLanguage: 'Kiswahili',
	},
	{
		name: 'Tamil',
		code: 'ta',
		typeface: 'Noto Sans',
		inLanguage: 'தமிழ்',
	},
	{
		name: 'Thai',
		code: 'th',
		typeface: 'Noto Sans',
		inLanguage: 'ภาษาไทย',
	},
	{
		name: 'Turkish',
		code: 'tr',
		typeface: 'Noto Sans',
		inLanguage: 'Türkçe',
	},
	{
		name: 'Urdu',
		code: 'ur',
		typeface: 'Noto Sans',
		inLanguage: 'اردو',
	},
	{
		name: 'Vietnamese',
		code: 'vi',
		typeface: 'Noto Sans',
		inLanguage: 'Tiếng Việt',
	},
]

export const translations = languages.filter(l => l.code !== 'en')